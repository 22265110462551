<template>
  <div class="mark-menage">
    <div class="mark-menage-left">
      <div>
        <el-form :model="submitForm" ref="submitForm">
          <el-form-item
            prop="groupName"
            :rules="{
              required: true,
              message: '群标记分类名称不能为空'
            }"
          >
            <el-input
              v-model="submitForm.groupName"
              clearable
              size="small"
              placeholder="请输入群标记分类名称"
              class="w_100"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="tipNames"
            :rules="{
              required: true,
              message: '标记名称不能为空'
            }"
          >
            <el-input
              size="small"
              class="w_100"
              placeholder="请输入标记名称,多个以空格间隔"
              type="textarea"
              v-model="submitForm.tipNames"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          size="mini"
          style="width: 100%; margin-top: 10px; border-radius: 16px"
          @click="addForm('submitForm')"
          :loading="loading"
        >
          提交创建
        </el-button>
      </div>
      <!-- <logPage
        title="近10条提交记录"
        ref="searchIndex3Log"
        height="calc(100% - 170px)"
        :logList="logList"
        label="groupName"
        @selectLog="selectLog"
      /> -->
    </div>
    <div class="mark-menage-right" v-loading="loading">
      <el-table
        :data="tableList"
        header-row-class-name="tableheader"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '0' }"
        border
        :height="windowsHeight - 160"
      >
        <el-table-column
          prop="groupName"
          label="群标记分类名称"
          width="150"
        ></el-table-column>
        <el-table-column prop="tipNames" label="标记名称"></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="160"
        ></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <!-- <el-button
              type="text"
              style="color:#66b1ff"
              @click="editFun(scope.row)"
            >
              修改
            </el-button> -->
            <el-button
              type="text"
              style="color:red"
              @click="delTipGroup(scope.row.id)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="total"
        :page-size="limit"
        :currentPage="current"
        :pagerCount="5"
        :background="false"
        :sizesArr="[30, 50, 100]"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />
    </div>
    <!-- 编辑弹框 -->
    <el-dialog
      title="群标记修改"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      class="mark-dialog"
    >
      <div class="mark-dialog-box">
        <el-form :model="editForm" label-width="130px" ref="editForm">
          <el-form-item
            label="群标记分类名称"
            prop="groupName"
            :rules="{
              required: true,
              message: '群标记分类名称不能为空'
            }"
          >
            <el-input
              v-model="editForm.groupName"
              clearable
              size="small"
              placeholder="请输入群标记分类名称"
              style="width:100%"
            ></el-input>
          </el-form-item>
          <el-form-item label="标记名称">
            <div class="mark-list" v-for="(item, i) in dialogMarkList" :key="i">
              <el-input
                size="small"
                v-model="item.name"
                style="width:calc(100% - 75px)"
                placeholder="请输入标记名称"
              ></el-input>
              <el-button
                size="small"
                :disabled="dialogMarkList.length == 1"
                style="width:60px;margin-left:10px"
                @click="deldialogMarkList(i)"
              >
                删除
              </el-button>
            </div>
            <el-button
              size="small"
              type="primary"
              class="el-icon-plus"
              @click="adddialogMarkList"
            >
              新增
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="editFormFun">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  tipGroup,
  addTipGroup,
  delTipGroup,
  editTipGroup
} from '@/api/mark/menage'
import { getLocalStorageFun, localStorageFun } from '@/plugins/logFunction'
export default {
  name: 'MarkManagement',
  data() {
    return {
      submitForm: {
        groupName: '',
        tipNames: ''
      },
      loading: false,
      logList: [],
      tableList: [],
      total: 0,
      limit: 30,
      current: 1,
      // 弹框
      dialogVisible: false,
      editForm: {
        groupName: ''
      },
      dialogMarkList: []
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    }
  },

  created() {
    this.getPage()
    // this.logList = getLocalStorageFun('markManegeLog')
  },
  methods: {
    getPage() {
      this.loading = true
      tipGroup(this.current, this.limit).then(data => {
        this.total = data.all_count
        this.tableList = data.items
        this.loading = false
      })
    },
    addForm(submitForm) {
      this.$refs[submitForm].validate(valid => {
        if (valid) {
          addTipGroup(this.submitForm).then(data => {
            // localStorageFun('markManegeLog', this.submitForm)
            // this.logList = getLocalStorageFun('markManegeLog')
            this.$message({
              type: 'success',
              message: '创建成功！'
            })
            this.submitForm.groupName = ''
            this.submitForm.tipNames = ''
            this.getPage()
            this.$nextTick(() => {
              this.$refs['submitForm'].resetFields()
            })
          })
        }
      })
    },
    selectLog() {
      this.$refs.searchIndex3Log.selectIndex = null
    },
    currentChange(val) {
      this.current = val
      this.getPage()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getPage()
    },
    // 弹框
    handleClose() {
      this.dialogVisible = false
    },
    // 删除
    delTipGroup(id) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delTipGroup(id).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getPage()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //
    editFun(row) {
      this.dialogMarkList = []
      console.log(row)
      this.editForm = Object.assign({}, row)
      if (row.tipList && row.tipList.length > 0) {
        this.dialogMarkList = row.tipList.map(item => {
          return {
            name: item.tipName,
            id: item.id
          }
        })
      }
      this.dialogVisible = true
    },
    // 确认修改
    editFormFun() {
      this.$refs['editForm'].validate(valid => {
        if (valid) {
          //  if(this.dialogMarkList)
          let obj = {
            groupName: this.editForm.groupName,
            tipNames: this.dialogMarkList
              .map(item => {
                return item.name
              })
              .join(' ')
          }
          editTipGroup(this.editForm.id, obj).then(data => {
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
            this.editForm.groupName = ''
            this.dialogMarkList = []
            this.getPage()
            this.$nextTick(() => {
              this.$refs['editForm'].resetFields()
              this.dialogVisible = false
            })
          })
        }
      })
    },
    //
    adddialogMarkList() {
      this.dialogMarkList.push({ name: '' })
    },
    deldialogMarkList(i) {
      this.dialogMarkList.splice(i, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.mark-menage {
  width: 100%;
  height: calc(100vh - 79px);
  background: #f0f2f5;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .mark-menage-left {
    width: 260px;
    height: 100%;
    background: white;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
    // ::v-deep .el-form-item {
    //   margin-bottom: 15px !important;
    // }
  }
  .mark-menage-right {
    width: calc(100% - 270px);
    height: 100%;
    background: white;
    overflow: hidden;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
    ::v-deep .tableheader th {
      background: rgb(243, 247, 249);
      padding: 8px 0;
    }
  }
}
.mark-dialog {
  .mark-dialog-box {
    overflow: hidden;
    // max-height: 300px;
    overflow-y: auto;
  }

  .mark-list {
    white-space: nowrap;
  }
}
</style>
